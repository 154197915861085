<template>
    <div class="company-contain">
        <div class="head-box">
            <div class="title">查看作品</div>
        </div>
        <div class="table-box">
            <el-table
                    :data="taskList"
                    class="college-table"
                    style="width: 100%;overflow: hidden"
                    size="medium"
                    :header-cell-style="{fontWeight: 'normal', color: '#333', background: '#f5f7fa',}"
                    :cell-style="{fontSize: '12px',color: '#333'}"
            >
                <el-table-column
                        prop="task_works_id"
                        label="序号"
                        width="140"
                        align="center"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.task_works_id}}</span>
                    </template>
                </el-table-column>

                <el-table-column prop="username" label="账号" align="left" class-name="student-name">
                    <template slot-scope="scope">
                        <div class="user-box">
                            <img class="imgBox" :src=scope.row.head_portrait>
                            <span class="student-name-title">{{scope.row.username}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="task_works_content" label="内容" align="left" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="task-works-content">{{scope.row.task_works_content}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="task_works_file" label="附件" align="center" class-name="student-name">
                    <template slot-scope="scope">
<!--                        <p @click="showPic(item.file_path)" v-for="item in scope.row.task_works_file" :key="item.id"-->
<!--                           class="student-name-title" style="cursor: pointer">{{item.file_name}}</p>-->
<!--                        <p @click="showPic(item.content.url?item.content.url:item.content.video_cover.url)"-->
<!--                           v-for="item in scope.row.task_works_library" :key="item.id"-->
<!--                           class="student-name-title" style="cursor: pointer">-->
<!--                            {{item.content.name?item.content.name:item.content.title}}</p>-->
                        <el-button type="text" size="small" @click="gotoDetail(scope.row)">查看详情
                        </el-button>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="提交日期" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.create_time}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button type="text" v-if="scope.row.task_works_state ==0 || scope.row.task_works_state==null"
                                   size="small" @click="eidtState(scope.row)">合格
                        </el-button>
                        <el-button type="text" v-if="scope.row.task_works_state ==0 ||scope.row.task_works_state==null"
                                   size="small" @click="eidtState1(scope.row)">不合格
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog
                title="报名"
                :visible.sync="dialogVisible"
                width="30%"
                :before-close="handleClose">
            <span v-if="this.state ==2">确定该作品为合格作品？确定后将无法修改</span>
            <span v-if="this.state ==1">确定该作品为不合格作品？确定后将无法修改</span>
            <span slot="footer" class="dialog-footer">
                <el-button @click="handleClose">取 消</el-button>
                <el-button type="primary" @click="changeState">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                :visible.sync="dialogVisible1"
                width="440px"
                :before-close="handleClose1">
            <iframe :src="picUrl" style="width: 400px;height: 300px;"></iframe>
        </el-dialog>
    </div>
</template>

<script>
    import {mapActions,mapMutations} from 'vuex'
    export default {
        name: 'taskPublish',
        data() {
            return {
                task_id: "",
                taskList: [],
                state: "",
                dialogVisible: false,
                dialogVisible1: false,
                task_works_id: "",
                picUrl: "",
            }
        },
        mounted() {
            this.getParam();
        },
        methods: {
            ...mapMutations(['setWorksInfo']),
            gotoDetail(row){
                if (row.task_works_state === '' || row.task_works_state === null) {
                    row.task_works_state = 0;
                }
                this.setWorksInfo(row)
                this.$router.push({
                    path: '/company/taskcenter/worksDetail',
                    query: {
                        sName: row.username,//学生姓名
                        cName: row.name,//班级名称
                        tState: row.task_works_state,//任务状态：合格/不合格/暂未操作
                        taskType:this.taskType, //任务类型 1普通 3实训 4脚本
                        taskId:this.$route.query.id,
                        userId: row.user_id,
                    }
                })
            },
            getParam() {
                this.task_id = this.$route.query.task_id;
                this.getList();
            },
            // 获取列表
            getList() {
                let param = {
                    task_id: this.task_id
                };
                this.$httpcompany.axiosGetBy(this.$api.worksList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.list;
                    }
                });
            },
            //合格
            eidtState(row) {
                this.state = 2;
                this.task_works_id = row.task_works_id;
                this.dialogVisible = true;
            },
            //不合格
            eidtState1(row) {
                this.state = 1;
                this.task_works_id = row.task_works_id;
                this.dialogVisible = true;
            },
            changeState() {
                let param = {
                    task_works_id: this.task_works_id,
                    state: this.state
                };
                this.$httpcompany.axiosGetBy(this.$api.worksState, param, res => {
                    if (res.code == 200) {
                        this.$message({
                            type: "success",
                            message: res.msg
                        })
                        this.getList();
                        this.handleClose();
                        this.state = "";
                    } else {
                        this.$message({
                            type: "error",
                            message: res.message,
                            duration: 1000
                        });
                    }
                });
            },
            showPic(val) {
                this.dialogVisible1 = true;
                this.picUrl = val;
            },
            handleClose() {
                this.dialogVisible = false;
                this.task_works_id = "";
                this.state = "";
            },
            handleClose1() {
                this.dialogVisible1 = false;
                this.picUrl = "";
            }
        },

    }
</script>

<style scoped lang="scss">
    .activeClass {
        background: #4d5aff;
        color: #ffffff;
    }

    .company-contain {
        height: calc(100vh - 100px);
        background-color: #ffffff;

        .head-box {
            display: flex;
            justify-content: space-between;
            height: 40px;
            padding: 10px 30px 0 20px;

            .title {
                margin-top: 10px;
            }
        }

        .divide {
            padding: 0 20px;
        }

        .filter-box {
            .box1 {
                padding: 0 20px 10px 20px;
                height: 40px;
                display: flex;

                span {
                    margin-top: 8px;
                    line-height: 32px;
                    display: inline-block;
                    width: 80px;
                    height: 32px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .college-table {
            .user-box {
                display: flex;
                height: 30px;
                justify-content: start;
                align-items: center;

                .img-box {
                    display: inline-block;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                }

                span {
                    margin-left: 6px;
                    max-width: 260px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }

            .task-works-content {
                max-width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }
</style>
